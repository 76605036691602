import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../../contextAPI/UserContext";

const Spinner = () => (
  <div class="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
    <div class="animate-pulse flex space-x-4">
      <div class="rounded-full bg-slate-700 h-10 w-10"></div>
      <div class="flex-1 space-y-6 py-1">
        <div class="h-2 bg-slate-700 rounded"></div>
        <div class="space-y-3">
          <div class="grid grid-cols-3 gap-4">
            <div class="h-2 bg-slate-700 rounded col-span-2"></div>
            <div class="h-2 bg-slate-700 rounded col-span-1"></div>
          </div>
          <div class="h-2 bg-slate-700 rounded"></div>
        </div>
      </div>
    </div>
  </div>
);

const PartnerUp = () => {
  const router = useNavigate();
  const [userauth] = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userauth || !userauth.token) {
      router("/"); // Redirect to login page if not authenticated
    } else {
      fetchPartnerUpData();
    }
  }, [userauth, router]);
  const [partnerUpData, setPartnerUpData] = useState([]);
  const [addPopupShow, setAddPopUpShow] = useState(false);
  const [editPopupShow, setEditPopUpShow] = useState(false);
  const [addPartnerUpData, setAddPartnerUpData] = useState({
    heading1: "",
    images1: [],
    heading2: "",
    images2: [],
  });
  const [editPartnerUpData, setEditPartnerUpData] = useState({
    heading1: "",
    images1: [],
    heading2: "",
    images2: [],
  });
  const [editId, setEditId] = useState(null);

  const fetchPartnerUpData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://conscientious-technologies-backend.vercel.app/get-partner-up-data"
      );
      if (response.status === 200) {
        setPartnerUpData(response.data.getdata);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire(
        "Error!",
        "Failed to fetch partner up data or may be data not available.",
        "error"
      );
    }
  };

  const handleAddPartnerUpDataChange = (field, value) => {
    setAddPartnerUpData({ ...addPartnerUpData, [field]: value });
  };

  const handleEditPartnerUpDataChange = (field, value) => {
    setEditPartnerUpData({ ...editPartnerUpData, [field]: value });
  };

  const handleAddFileChange = (e, index, field) => {
    const files = Array.from(e.target.files);
    const updatedImages = [...addPartnerUpData[field]];
    updatedImages[index] = files[0]; // Replace existing file or add new one if it doesn't exist
    setAddPartnerUpData({ ...addPartnerUpData, [field]: updatedImages });
  };

  const handleEditFileChange = (e, index, field) => {
    const files = Array.from(e.target.files);
    const updatedImages = [...editPartnerUpData[field]];
    updatedImages[index] = files[0]; // Replace existing file or add new one if it doesn't exist
    setEditPartnerUpData({ ...editPartnerUpData, [field]: updatedImages });
  };

  const handleAddImageField = (field) => {
    setAddPartnerUpData({
      ...addPartnerUpData,
      [field]: [...addPartnerUpData[field], null],
    });
  };

  const handleEditImageField = (field) => {
    setEditPartnerUpData({
      ...editPartnerUpData,
      [field]: Array.isArray(editPartnerUpData[field])
        ? [...editPartnerUpData[field], null]
        : [null],
    });
  };

  const removeAddFile = (index, field) => {
    const updatedImages = addPartnerUpData[field].filter((_, i) => i !== index);
    setAddPartnerUpData({ ...addPartnerUpData, [field]: updatedImages });
  };

  const removeEditFile = (index, field) => {
    const updatedImages = editPartnerUpData[field].filter(
      (_, i) => i !== index
    );
    setEditPartnerUpData({ ...editPartnerUpData, [field]: updatedImages });
  };

  const addPartnerUpDataFunc = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("heading1", addPartnerUpData.heading1);
      formData.append("heading2", addPartnerUpData.heading2);
      addPartnerUpData.images1.forEach((image) =>
        formData.append("images1", image)
      );
      addPartnerUpData.images2.forEach((image) =>
        formData.append("images2", image)
      );

      const response = await axios.post(
        "https://conscientious-technologies-backend.vercel.app/add-partner-up-data",
        formData
      );

      if (response.status === 200) {
        fetchPartnerUpData();
        setAddPopUpShow(false);
        setAddPartnerUpData({
          heading1: "",
          images1: [],
          heading2: "",
          images2: [],
        });
        Swal.fire("Success!", "Data added successfully.", "success");
      }
    } catch (error) {
      Swal.fire(
        "Error!",
        "Failed to add data. Please try again later.",
        "error"
      );
    }
  };

  const editPartnerUpDataFunc = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("heading1", editPartnerUpData.heading1);
      formData.append("heading2", editPartnerUpData.heading2);
      editPartnerUpData.images1.forEach((image) =>
        formData.append("images1", image)
      );
      editPartnerUpData.images2.forEach((image) =>
        formData.append("images2", image)
      );

      const response = await axios.put(
        `https://conscientious-technologies-backend.vercel.app/edit-partner-up-data/${editId}`,
        formData
      );
      if (response.status === 200) {
        setEditPopUpShow(false);
        setEditPartnerUpData({
          heading1: "",
          images1: [],
          heading2: "",
          images2: [],
        });
        fetchPartnerUpData();
        Swal.fire("Saved!", "Your changes have been saved.", "success");
      }
    } catch (error) {
      Swal.fire(
        "Error!",
        "Failed to save changes. Please try again later.",
        "error"
      );
    }
  };

  const deletePartnerUpData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(
            `https://conscientious-technologies-backend.vercel.app/delete-partner-up-data/${id}`
          );
          if (response.status === 200) {
            fetchPartnerUpData();
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
          }
        } catch (error) {
          Swal.fire(
            "Error!",
            "Failed to delete data. Please try again later.",
            "error"
          );
        }
      }
    });
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="w-full bg-gray-200 h-full mx-auto p-4">
          <div className="flex justify-between mb-4 mr-3 gap-x-3">
            <h1 className="text-2xl font-bold">PartnerUp</h1>
            <Button
              onClick={() => setAddPopUpShow(true)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0 px-2 rounded"
            >
              Add PartnerUp Tools
            </Button>
          </div>
          <Modal show={addPopupShow} onHide={() => setAddPopUpShow(false)}>
            <Modal.Header closeButton className="bg-gray-800 text-white">
              <Modal.Title>Add PartnerUp Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form
                onSubmit={addPartnerUpDataFunc}
                className="mx-auto max-w-lg"
              >
                <fieldset className="mb-4">
                  <label className="block text-gray-700 font-bold">
                    First Heading
                  </label>
                  <input
                    type="text"
                    value={addPartnerUpData.heading1}
                    onChange={(e) =>
                      handleAddPartnerUpDataChange("heading1", e.target.value)
                    }
                    className="form-input mt-1 block w-full rounded-md border border-gray-300 focus:border-blue-500"
                  />
                </fieldset>
                <fieldset>
                  <label>Images</label>
                  {addPartnerUpData.images1.map((image, index) => (
                    <div key={index} className="my-2 flex items-center">
                      <input
                        type="file"
                        onChange={(e) =>
                          handleAddFileChange(e, index, "images1")
                        }
                      />
                      <button
                        type="button"
                        onClick={() => removeAddFile(index, "images1")}
                        className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="border border-black px-2 py-1"
                    onClick={() => handleAddImageField("images1")}
                  >
                    Add Image
                  </button>
                </fieldset>
                <fieldset className="mb-4">
                  <label className="block text-gray-700 font-bold">
                    Second Heading
                  </label>
                  <input
                    type="text"
                    value={addPartnerUpData.heading2}
                    onChange={(e) =>
                      handleAddPartnerUpDataChange("heading2", e.target.value)
                    }
                    className="form-input mt-1 block w-full rounded-md border border-gray-300 focus:border-blue-500"
                  />
                </fieldset>
                <fieldset>
                  <label>Images</label>
                  {addPartnerUpData.images2.map((image, index) => (
                    <div key={index} className="my-2 flex items-center">
                      <input
                        type="file"
                        onChange={(e) =>
                          handleAddFileChange(e, index, "images2")
                        }
                      />
                      <button
                        type="button"
                        onClick={() => removeAddFile(index, "images2")}
                        className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="border border-black px-2 py-1"
                    onClick={() => handleAddImageField("images2")}
                  >
                    Add Image
                  </button>
                </fieldset>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded flex mx-auto"
                >
                  Save
                </button>
              </form>
            </Modal.Body>
          </Modal>
          <Modal show={editPopupShow} onHide={() => setEditPopUpShow(false)}>
            <Modal.Header closeButton className="bg-gray-800 text-white">
              <Modal.Title>Edit PartnerUp Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form
                onSubmit={editPartnerUpDataFunc}
                className="mx-auto max-w-lg"
              >
                <fieldset className="mb-4">
                  <label className="block text-gray-700 font-bold">
                    First Heading
                  </label>
                  <input
                    type="text"
                    value={editPartnerUpData.heading1}
                    onChange={(e) =>
                      handleEditPartnerUpDataChange("heading1", e.target.value)
                    }
                    className="form-input mt-1 block w-full rounded-md border border-gray-300 focus:border-blue-500"
                  />
                </fieldset>
                <fieldset>
                  <label>Images</label>
                  {Array.isArray(editPartnerUpData.images1) &&
                    editPartnerUpData.images1.map((image, index) => (
                      <div key={index} className="my-2 flex items-center">
                        <input
                          type="file"
                          onChange={(e) =>
                            handleEditFileChange(e, index, "images1")
                          }
                        />
                        <button
                          type="button"
                          onClick={() => removeEditFile(index, "images1")}
                          className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  <button
                    type="button"
                    className="border border-black px-2 py-1"
                    onClick={() => handleEditImageField("images1")}
                  >
                    Add Image
                  </button>
                </fieldset>
                <fieldset className="mb-4">
                  <label className="block text-gray-700 font-bold">
                    Second Heading
                  </label>
                  <input
                    type="text"
                    value={editPartnerUpData.heading2}
                    onChange={(e) =>
                      handleEditPartnerUpDataChange("heading2", e.target.value)
                    }
                    className="form-input mt-1 block w-full rounded-md border border-gray-300 focus:border-blue-500"
                  />
                </fieldset>
                <fieldset>
                  <label>Images</label>
                  {Array.isArray(editPartnerUpData.images2) &&
                    editPartnerUpData.images2.map((image, index) => (
                      <div key={index} className="my-2 flex items-center">
                        <input
                          type="file"
                          onChange={(e) =>
                            handleEditFileChange(e, index, "images2")
                          }
                        />
                        <button
                          type="button"
                          onClick={() => removeEditFile(index, "images2")}
                          className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                        >
                          Remove
                        </button>
                      </div>
                    ))}

                  <button
                    type="button"
                    className="border border-black px-2 py-1"
                    onClick={() => handleEditImageField("images2")}
                  >
                    Add Image
                  </button>
                </fieldset>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded flex mx-auto"
                >
                  Save
                </button>
              </form>
            </Modal.Body>
          </Modal>
          <table className="w-full border-collapse border bg-white">
            <thead className="bg-gray-800 text-white">
              <tr className="border-b">
                <th className="border-r p-2">Sr. No</th>
                <th className="border-r p-2">Heading1</th>
                <th className="border-r p-2">Heading2</th>
                <th className="border-r p-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {partnerUpData &&
                partnerUpData.map((partner, i) => (
                  <tr key={partner._id} className="border-b">
                    <td className="border-r p-2">{i + 1}</td>
                    <td className="border-r p-2">{partner.heading1}</td>
                    <td className="border-r p-2">{partner.heading2}</td>
                    <td className="border-r flex items-center justify-start gap-[20px] p-2">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 px-[20px] py-[7px] text-white font-bold rounded"
                        onClick={() => {
                          setEditPopUpShow(true);
                          setEditId(partner._id);
                          setEditPartnerUpData({
                            heading1: partner.heading1,
                            images1:
                              partner.images1 &&
                              partner.images1.map((image) => image),
                            heading2: partner.heading2,
                            images2:
                              partner.images2 &&
                              partner.images2.map((image) => image),
                          });
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="hover:bg-red-700 bg-red-500 px-[20px] py-[7px] rounded text-white shadow-md"
                        onClick={() => deletePartnerUpData(partner._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PartnerUp;
